export const zIndexes = {
  header: 5,
  footer: 4,
  sideNav: 3,
  // 10~99: modal
  baseModal: 10,
  detailModal: 11,
  confirmModal: 12,
  alretModal: 13,
  // 100~199: select, combobox, dropdown
  select: 100,
  // 200~299:  toast, image viewer
  imageViewer: 201,
  // 500~599:  toast
  toast: 500,
}
