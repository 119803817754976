import { Grid, GridOverrides, GridProps } from 'baseui/layout-grid'
import { StyleObject } from 'styletron-react'
import { useOverrides } from '../hooks/use-overrides'
import { Spaces, useSpace } from '../hooks/use-space'

interface BaseGridProps extends GridProps {
  style?: StyleObject
  spaceTop?: Spaces
  spaceBottom?: Spaces
}

export const BaseGrid = ({
  style,
  children,
  overrides,
  spaceTop,
  spaceBottom,
  ...props
}: BaseGridProps) => {
  const { marginTop, marginBottom } = useSpace({ spaceTop, spaceBottom })

  return (
    <Grid
      overrides={useOverrides<GridOverrides>(
        {
          Grid: {
            style: {
              boxSizing: 'content-box',
              ...marginTop,
              ...marginBottom,
              ...style,
            },
          },
        },
        overrides
      )}
      {...props}
    >
      {children}
    </Grid>
  )
}
