import { forwardRef } from 'react'
import {
  ParagraphLarge,
  ParagraphMedium,
  ParagraphSmall,
} from 'baseui/typography'
import { TypographyProps } from './typography.interface'
import { colors } from '@styles/colors'

export const ParagraphL = forwardRef<HTMLDivElement, TypographyProps>(
  (
    {
      children,
      weight,
      align,
      color = colors.gray700,
      $style,
      whiteSpace,
      ...props
    },
    ref
  ) => (
    <ParagraphLarge
      font="ParagraphL"
      $style={{ fontWeight: weight, textAlign: align, whiteSpace, ...$style }}
      color={color}
      {...props}
      ref={ref}
    >
      {children}
    </ParagraphLarge>
  )
)

export const ParagraphM = forwardRef<HTMLDivElement, TypographyProps>(
  (
    {
      children,
      weight,
      align,
      color = colors.gray700,
      $style,
      whiteSpace,
      ...props
    },
    ref
  ) => (
    <ParagraphMedium
      font="ParagraphM"
      $style={{ fontWeight: weight, textAlign: align, whiteSpace, ...$style }}
      color={color}
      {...props}
      ref={ref}
    >
      {children}
    </ParagraphMedium>
  )
)

export const ParagraphS = forwardRef<HTMLDivElement, TypographyProps>(
  (
    {
      children,
      weight,
      align,
      color = colors.gray700,
      whiteSpace,
      $style,
      ...props
    },
    ref
  ) => (
    <ParagraphSmall
      font="ParagraphS"
      $style={{ fontWeight: weight, textAlign: align, whiteSpace, ...$style }}
      color={color}
      {...props}
      ref={ref}
    >
      {children}
    </ParagraphSmall>
  )
)

export const ParagraphXS = forwardRef<HTMLDivElement, TypographyProps>(
  (
    {
      children,
      weight,
      align,
      color = colors.gray700,
      whiteSpace,
      $style,
      ...props
    },
    ref
  ) => (
    <ParagraphSmall
      font="ParagraphXS"
      $style={{ fontWeight: weight, textAlign: align, whiteSpace, ...$style }}
      color={color}
      {...props}
      ref={ref}
    >
      {children}
    </ParagraphSmall>
  )
)
