import { BaseCell } from '@components/base-cell'
import { BaseGrid } from '@components/base-grid'
import { LabelS, ParagraphXS } from '@components/base-typography'
import { Icon } from '@components/icon'
import { Logo } from '@components/logo'
import { Routes } from '@lib/routes'
import { colors } from '@styles/colors'
import { mediaQuery } from '@styles/media-query'
import { typography } from '@styles/typography'
import { borderRadius } from '@styles/utils'
import Link from 'next/link'
import { padding } from 'polished'
import { useStyletron } from 'styletron-react'
import { socialMedia } from './menus'

export const LandingFooter = () => {
  const [css] = useStyletron()

  return (
    <footer
      className={css({
        boxShadow: 'inset 0px 1px 0px #EAEAEA',
        ...padding('48px', '0px', '64px'),
        [mediaQuery.TABLET]: {
          ...padding('64px', '0px', '72px'),
        },
      })}
    >
      <BaseGrid gridMaxWidth={1040}>
        <BaseCell span={[4, 6, 10]}>
          <Logo size="xs" />
          <ul
            className={css({
              marginTop: '32px',
              display: 'flex',
            })}
          >
            {[
              {
                label: '서비스 이용약관',
                href: Routes.LEGAL_TERMS_OF_SERVICE,
              },
              {
                label: '유료 서비스 이용약관',
                href: Routes.LEGAL_TERMS_OF_USE_FOR_PAID_SERVICES,
              },
              {
                label: '개인정보 처리방침',
                href: Routes.LEGAL_PRIVACY_POLICY,
              },
              {
                label: '회사 소개',
                href: Routes.TESSER,
              },
              {
                label: '제휴/협력 문의',
                href: Routes.TESSER_CONTACT,
              },
            ].map(({ label, href }) => (
              <li
                key={label}
                className={css({
                  display: 'flex',
                  alignItems: 'center',
                  ':not(:last-child)': {
                    '::after': {
                      content: '"・"',
                      ...padding('0px', '2px'),
                    },
                  },
                })}
              >
                <Link href={href}>
                  <a
                    target="_blank"
                    className={css({
                      ...typography.LabelXS,
                      fontWeight: 500,
                      color: colors.gray700,
                    })}
                  >
                    {label}
                  </a>
                </Link>
              </li>
            ))}
          </ul>
          <ParagraphXS marginTop="16px" color={colors.gray500}>
            (주) 테서 | 대표이사: 이수현 | 사업자등록번호: 404-81-46323 | 주소:
            서울시 강남구 언주로 544, 삼본빌딩 4F | 연락처: +82-2-575-1593 |
            tesser@tesser.co.kr
          </ParagraphXS>
        </BaseCell>
        <BaseCell span={[4, 2, 2]} spaceTop={[48, 0, 0]}>
          <div
            className={css({
              display: 'flex',
              flexDirection: 'column',

              [mediaQuery.TABLET]: {
                alignItems: 'flex-end',
              },
            })}
          >
            <LabelS>🇰🇷 한국어</LabelS>
            <ul
              className={css({
                display: 'flex',
                columnGap: '24px',
                marginTop: '48px',
                [mediaQuery.TABLET]: {
                  marginTop: '64px',
                },
              })}
            >
              {socialMedia.map(({ label, href, icon }) => (
                <li key={label}>
                  <Link href={href} passHref>
                    <a
                      aria-label={label}
                      target="_blank"
                      className={css({
                        display: 'flex',
                        ...padding('2px'),
                        ...borderRadius(32),
                      })}
                    >
                      <Icon type={icon} />
                    </a>
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </BaseCell>
      </BaseGrid>
    </footer>
  )
}
