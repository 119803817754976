import { Cell, CellOverrides, CellProps } from 'baseui/layout-grid'
import { StyleObject } from 'styletron-react'
import { useOverrides } from '../hooks/use-overrides'
import { useSpace, Spaces } from '../hooks/use-space'

interface BaseCellProps extends CellProps {
  style?: StyleObject
  spaceTop?: Spaces
  spaceBottom?: Spaces
}

export const BaseCell = ({
  style,
  children,
  overrides,
  spaceTop,
  spaceBottom,
  span = [4, 8, 12],
  ...props
}: BaseCellProps) => {
  const { marginTop, marginBottom } = useSpace({ spaceTop, spaceBottom })

  return (
    <Cell
      overrides={useOverrides<CellOverrides>(
        {
          Cell: {
            style: {
              ...marginTop,
              ...marginBottom,
              ...style,
            },
          },
        },
        overrides
      )}
      span={span}
      {...props}
    >
      {children}
    </Cell>
  )
}
