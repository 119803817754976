import { UserRole } from '@lib/graphql/documents.generated'
import { Routes } from '@lib/routes'
import { IconType } from '../icon'

export const footerMenus: Array<{
  title: string
  menus: Array<{
    label: string
    href: string
    role?: UserRole[]
    blank?: boolean
  }>
}> = [
  {
    title: '메뉴',
    menus: [
      {
        label: '해석 신청',
        href: Routes.UPLOAD,
      },
      {
        label: '내 검사 결과지',
        href: Routes.PATIENT_EXAMINATIONS,
      },
      // {
      //   label: '이용 가이드',
      //   href: Routes.NOTION_GUIDE,
      //   blank: true,
      // },
      {
        label: '설정',
        href: Routes.SETTINGS_ACCOUNT,
      },
    ],
  },
  {
    title: '문의',
    menus: [
      {
        label: '해석 참여 신청',
        href: Routes.DOCTOR_REGISTRATION,
        role: [UserRole.Professional],
      },
      {
        label: '제휴 문의',
        href: Routes.SUPPORT_INQUIRY_WRITE,
      },
    ],
  },
  {
    title: '고객지원',
    menus: [
      {
        label: '공지사항',
        href: Routes.SUPPORT_NOTICE,
      },
      {
        label: '자주 묻는 질문',
        href: Routes.SUPPORT_FAQ,
      },
      {
        label: '1대1 문의',
        href: Routes.SUPPORT_INQUIRY_WRITE,
      },
    ],
  },
]

export const socialMedia: Array<{
  label: string
  icon: IconType
  colorIcon: IconType
  href: string
}> = [
  {
    label: 'facebook',
    icon: 'Facebook',
    colorIcon: 'FacebookColor',
    href: 'https://www.facebook.com/tesserinc',
  },
  {
    label: 'instagram',
    icon: 'Instagram',
    colorIcon: 'InstagramColor',
    href: 'https://www.instagram.com/tesserdata',
  },
  {
    label: 'blog',
    icon: 'Naver',
    colorIcon: 'NaverColor',
    href: 'https://blog.naver.com/tesserinc',
  },
]
