import { forwardRef } from 'react'
import {
  HeadingXXLarge,
  HeadingXLarge,
  HeadingLarge,
  HeadingMedium,
  HeadingSmall,
  HeadingXSmall,
} from 'baseui/typography'
import { colors } from '@styles/colors'
import { TypographyProps } from './typography.interface'

export const HeadingXXL = forwardRef<HTMLDivElement, TypographyProps>(
  (
    {
      children,
      weight,
      align,
      color = colors.gray700,
      whiteSpace,
      $style,
      ...props
    },
    ref
  ) => (
    <HeadingXXLarge
      font={[
        'MobileHeadingXXL',
        'MobileHeadingXXL',
        'TabletHeadingXXL',
        'HeadingXXL',
      ]}
      $style={{
        fontWeight: weight,
        textAlign: align,
        whiteSpace,
        ...$style,
      }}
      color={color}
      {...props}
      ref={ref}
    >
      {children}
    </HeadingXXLarge>
  )
)

export const HeadingXL = forwardRef<HTMLDivElement, TypographyProps>(
  (
    {
      children,
      weight,
      align,
      color = colors.gray700,
      whiteSpace,
      $style,
      ...props
    },
    ref
  ) => (
    <HeadingXLarge
      font={[
        'MobileHeadingXL',
        'MobileHeadingXL',
        'TabletHeadingXL',
        'HeadingXL',
      ]}
      $style={{
        fontWeight: weight,
        textAlign: align,
        whiteSpace,
        ...$style,
      }}
      color={color}
      {...props}
      ref={ref}
    >
      {children}
    </HeadingXLarge>
  )
)

export const HeadingL = forwardRef<HTMLDivElement, TypographyProps>(
  (
    {
      children,
      weight,
      align,
      color = colors.gray700,
      whiteSpace,
      $style,
      ...props
    },
    ref
  ) => (
    <HeadingLarge
      font={['MobileHeadingL', 'MobileHeadingL', 'TabletHeadingL', 'HeadingL']}
      $style={{
        fontWeight: weight,
        textAlign: align,
        whiteSpace,
        ...$style,
      }}
      color={color}
      {...props}
      ref={ref}
    >
      {children}
    </HeadingLarge>
  )
)

export const HeadingM = forwardRef<HTMLDivElement, TypographyProps>(
  (
    {
      children,
      weight,
      align,
      color = colors.gray700,
      whiteSpace,
      $style,
      ...props
    },
    ref
  ) => (
    <HeadingMedium
      font={['MobileHeadingM', 'MobileHeadingM', 'TabletHeadingM', 'HeadingM']}
      $style={{ fontWeight: weight, textAlign: align, whiteSpace, ...$style }}
      color={color}
      {...props}
      ref={ref}
    >
      {children}
    </HeadingMedium>
  )
)

export const HeadingS = forwardRef<HTMLDivElement, TypographyProps>(
  (
    {
      children,
      weight,
      align,
      color = colors.gray700,
      whiteSpace,
      $style,
      ...props
    },
    ref
  ) => (
    <HeadingSmall
      font="HeadingS"
      $style={{ fontWeight: weight, textAlign: align, whiteSpace, ...$style }}
      color={color}
      {...props}
      ref={ref}
    >
      {children}
    </HeadingSmall>
  )
)

export const HeadingXS = forwardRef<HTMLDivElement, TypographyProps>(
  (
    {
      children,
      weight,
      align,
      color = colors.gray700,
      whiteSpace,
      $style,
      ...props
    },
    ref
  ) => (
    <HeadingXSmall
      font="HeadingXS"
      $style={{ fontWeight: weight, textAlign: align, whiteSpace, ...$style }}
      color={color}
      {...props}
      ref={ref}
    >
      {children}
    </HeadingXSmall>
  )
)
