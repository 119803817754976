import { useMemo } from 'react'
import { useStyletron } from 'styletron-react'
import classnames from 'classnames'
import LogoIcon from 'public/svg/logo/basic-logo.svg'
import Link from 'next/link'
import { Routes } from '@lib/routes'

const logos = {
  icon: LogoIcon,
  ko: LogoIcon,
  en: LogoIcon,
} as const

type LogoType = 'icon' | 'ko' | 'en'
type LogoSize = 'xs' | 's' | 'm' | 'l' | 'xl'

interface LogoProps {
  type?: LogoType
  size?: LogoSize
  className?: string
  onClick?: React.MouseEventHandler<SVGSVGElement>
  active?: boolean
  href?: string
}

export const Logo = ({
  type = 'en',
  size = 's',
  className,
  onClick,
  active,
  href = Routes.HOME,
}: LogoProps) => {
  const [css] = useStyletron()
  const Component = useMemo(() => logos[type], [type])

  const height = {
    xs: 16,
    s: 24,
    m: 32,
    l: 48,
    xl: 64,
  }[size]

  const icon = useMemo(
    () => (
      <Component
        height={height}
        className={classnames(
          'logo',
          className,
          css({
            display: 'inline-flex',
            cursor: Boolean(onClick) ? 'pointer' : undefined,
          })
        )}
        onClick={onClick}
      />
    ),
    [Component, className, css, height, onClick]
  )

  return active ? (
    <Link href={href}>
      <a aria-label="ontol-logo">{icon}</a>
    </Link>
  ) : (
    icon
  )
}
